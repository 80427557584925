var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"home d-flex"},[_c('v-card',{staticClass:"d-flex flex-grow-1"},[_c('v-container',{staticClass:"d-flex flex-grow-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-grow-1",staticStyle:{"width":"100%"},attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"8","md":"8","xs":"12","cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"outlined":"","id":"monitorHomeTutorial"}},[_c('v-card-title',{staticClass:"pt-1",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left",staticStyle:{"margin-left":"0","width":"50%","font-size":"13px","font-weight":"normal"}},[_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"default","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedMode.title)+" MODE ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.modes),function(item){return _c('v-list-item',{key:item.value,on:{"click":function () {
                                                    _vm.selectedMode = item;
                                                }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('div',{staticClass:"text-right",staticStyle:{"margin-right":"0","width":"50%"}},[_c('span',{attrs:{"id":"monitorFunctions"}},[(
                                            !_vm.VUETIFY_BREAKPOINT.mobile &&
                                            _vm.allowDownloadAll
                                        )?[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":"primary","dense":"","loading":_vm.allBookDownloadLoading},on:{"click":_vm.downloadAllBooks}},[_vm._v("Download All")])]:_vm._e(),(!_vm.VUETIFY_BREAKPOINT.mobile)?[_c('v-icon',{attrs:{"color":"green","dark":"","dense":""},on:{"click":_vm.startDownload}},[_vm._v("mdi-microsoft-excel")]),_c('download-excel',{staticStyle:{"display":"none"},attrs:{"id":"csvExport","fetch":_vm.fetchData,"fields":_vm.computedCSVFields,"type":"csv","name":_vm.downloadExcelName,"escapeCsv":false}})]:_vm._e(),_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"default","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.detailIntervalOptions.find( function (option) { return option.value === _vm.selectedDetailInterval; } ).key)+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.detailIntervalOptions),function(item){return _c('v-list-item',{key:item.key,on:{"click":function () {
                                                        _vm.selectedDetailInterval =
                                                            item.value;
                                                    }}},[_c('v-list-item-title',[_vm._v(_vm._s(item.key))])],1)}),1)],1)],2)])]),_c('v-row',{staticClass:"flex-shrink-0 flex-grow-0",staticStyle:{"margin":"0"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-6",staticStyle:{"padding":"0"},attrs:{"cols":_vm.briefCol}},[_c('DanMenu',{attrs:{"name":_vm.selectedBookName,"menu-items":_vm.books},on:{"dan-menu-click":_vm.onMenuItemClick}})],1),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":_vm.briefCol,"align":"center"}},[_c('CardHead',{attrs:{"name":'Unrealized',"value":_vm.mainBookTitle.unrealized,"description":_vm.mainBookTitleDesc.unrealized}})],1),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":_vm.briefCol,"align":"center"}},[_c('CardHead',{attrs:{"name":'Realized',"value":_vm.mainBookTitle.realized,"description":_vm.mainBookTitleDesc.realized}})],1),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":_vm.briefCol,"align":"center"}},[_c('CardHead',{attrs:{"name":'Daily Net',"value":_vm.mainBookTitle.dailyNet,"description":_vm.mainBookTitleDesc.dailyNet}})],1)],1),_c('v-divider'),_c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:({
                                onEnd: _vm.sortPKHeadersAndUpdateTheKey,
                            }),expression:"{\n                                onEnd: sortPKHeadersAndUpdateTheKey,\n                            }"}],key:_vm.anIncreasingNumberForMain,staticClass:"elevation-0 flex-grow-1",class:{
                                'fix-first-col': _vm.VUETIFY_BREAKPOINT.mobile,
                            },attrs:{"dense":"","mobile-breakpoint":0,"headers":_vm.mainHeader,"items":_vm.computedPKData,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"header-props":{ sortIcon: null },"custom-filter":_vm.filterTable,"customSort":_vm.customSort,"item-key":"symbol","items-per-page":30,"footer-props":{
                                itemsPerPageOptions: [10, 20, 30],
                                showFirstLastPage: false,
                                'items-per-page-text': '',
                            },"id":"home-table","height":_vm.tableHeight,"fixed-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"header.symbol",fn:function(ref){
                            var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","id":"symbolSearch"},on:{"click":function($event){$event.preventDefault();return _vm.onSearch('symbolSearch')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"symbolSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter the search term","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.search = ''}}},[_vm._v(" Clear ")])],1)])]}},{key:"header.net",fn:function(ref){
                            var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Net Volume = Long + Short (Sort by absolute value)")])])]}},{key:"item.symbol",fn:function(ref){
                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"background":"transparent","padding":"0px","color":"white"},attrs:{"id":"symbolEODDialog"},on:{"click":function($event){$event.stopPropagation();return (function () {
                                                    _vm.getEODSymbolMaps(
                                                        item.symbol
                                                    );
                                                })($event)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.symbol))])]}}],null,true)},[_c('TooltipTable',{attrs:{"monitor-item":item}})],1)]}},{key:"item.net",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.netStr)+" ")]}},{key:"item.dailyNet",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.dailyNetStr)+" ")]}},{key:"item.longPositions",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.longPositionsStr)+" ")]}},{key:"item.shortPositions",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.shortPositionsStr)+" ")]}},{key:"item.floatingProfitEOD",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{class:("" + (item.color))},[_vm._v(" "+_vm._s(item.floatingProfitEODStr)+" ")])]}},{key:"item.closedProfitEOD",fn:function(ref){
                                                var item = ref.item;
return [_c('a',{staticStyle:{"background":"transparent","padding":"0px","color":"white"},on:{"click":function($event){$event.stopPropagation();return (function () {
                                            _vm.getPNLSummary(item);
                                        })($event)}}},[_vm._v(" "+_vm._s(item.closedProfitEODStr))])]}},{key:"item.closedProfit",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.closedProfitStr)+" ")]}},{key:"item.floatingProfit",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.floatingProfitStr)+" ")]}},{key:"item.symbolExposure",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbolExposureStr)+" ")]}},{key:"item.weightedAvgLongPrice",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.weightedAvgLongPriceStr)+" ")]}},{key:"item.weightedAvgShortPrice",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.weightedAvgShortPriceStr)+" ")]}}])})],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"lg":"4","md":"4","xs":"12","cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('AllBooks',{ref:"allBooks",attrs:{"mtdPnl":_vm.mtdPnl,"mtdVolume":_vm.mtdVolume,"tableHeight":_vm.allBookTableHeight,"id":"allBookHomeTutorial"},on:{"changeBook":_vm.changeSelectedBook}}),_c('ExposureChart',{ref:"exposureChart",staticClass:"mt-2",staticStyle:{"margin-top":"10px"},attrs:{"exposureData":_vm.exposureData,"chartHeight":_vm.exposureChartHeight,"id":"exposureHomeTutorial"}})],1)])],1),_c('v-dialog',{attrs:{"width":_vm.dialogWidth,"hide-overlay":true,"persistent":true,"fullscreen":_vm.fullscreen},model:{value:(_vm.dialogCloseSummaryVisible),callback:function ($$v) {_vm.dialogCloseSummaryVisible=$$v},expression:"dialogCloseSummaryVisible"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v("Close Summary - "+_vm._s(_vm.closeSummarySymbol)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogCloseSummaryVisible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 pl-0",attrs:{"cols":"12"}},[_c('v-tabs',[_c('v-tab',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeTab('login')}}},[_vm._v("PNL by Login")]),_c('v-tab',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeTab('symbol')}}},[_vm._v("PNL by Symbol")])],1)],1)],1),(_vm.selectedKey === 'login')?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{class:{
                                        'fix-first-col':
                                            _vm.VUETIFY_BREAKPOINT.mobile,
                                    },attrs:{"headers":_vm.closeSummaryLoginHeaders,"items":_vm.closeSummaryLoginData,"dense":"","header-props":{ sortIcon: null },"search":_vm.searchCloseSummaryLogin,"items-per-page":15,"loading":_vm.loading,"mobile-breakpoint":0,"footer-props":{
                                        'items-per-page-text': '',
                                    }},scopedSlots:_vm._u([{key:"header.login",fn:function(ref){
                                    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.onSearch(
                                                            'loginSearch'
                                                        )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"loginSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter the search term","single-line":"","hide-details":""},model:{value:(
                                                        _vm.searchCloseSummaryLogin
                                                    ),callback:function ($$v) {
                                                        _vm.searchCloseSummaryLogin
                                                    =$$v},expression:"\n                                                        searchCloseSummaryLogin\n                                                    "}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.searchCloseSummaryLogin =
                                                            ''}}},[_vm._v(" Clear ")])],1)])]}},{key:"item.profit",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.profit.toFixed(2) ))+" ")]}}],null,false,2103873324)})],1)],1):_vm._e(),(_vm.selectedKey === 'symbol')?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{class:{
                                        'fix-first-col':
                                            _vm.VUETIFY_BREAKPOINT.mobile,
                                    },attrs:{"headers":_vm.closeSummarySymbolHeaders,"items":_vm.closeSummarySymbolData,"dense":"","header-props":{ sortIcon: null },"items-per-page":10,"loading":_vm.loading,"mobile-breakpoint":0,"footer-props":{
                                        'items-per-page-text': '',
                                    }},scopedSlots:_vm._u([{key:"item.profit",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.profit.toFixed(2) ))+" ")]}}],null,false,670227873)})],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":800,"hide-overlay":true,"persistent":true,"fullscreen":_vm.fullscreen},model:{value:(_vm.symbolMap),callback:function ($$v) {_vm.symbolMap=$$v},expression:"symbolMap"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v("EOD Symbol Price Map - "+_vm._s(_vm.selectedSymbolMap)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.symbolMap = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{class:{
                                        'fix-first-col':
                                            _vm.VUETIFY_BREAKPOINT.mobile,
                                    },attrs:{"headers":_vm.symbolMapHeader,"items":!!_vm.selectedSymbolMap
                                            ? _vm.computedSymbolPriceMapData[
                                                  _vm.selectedSymbolMap
                                              ]
                                            : [],"dense":"","header-props":{ sortIcon: null },"items-per-page":15,"mobile-breakpoint":0,"footer-props":{
                                        'items-per-page-text': '',
                                    }}})],1)],1)],1)],1)],1),(_vm.loginSummaryHistoryVisible)?_c('LoginSummaryDialog',{attrs:{"fullLogin":_vm.fullLoginValue,"login":_vm.loginValue,"server":_vm.serverValue,"symbol":_vm.symbolValue,"book":_vm.selectedBook}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"1000","persistent":true},model:{value:(_vm.csvFieldsDialog),callback:function ($$v) {_vm.csvFieldsDialog=$$v},expression:"csvFieldsDialog"}},[_c('v-card',[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(" CSV Table Fields ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.cancelDownload}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex"},attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"CSV export name","rules":_vm.inputRules,"hide-details":"","required":""},model:{value:(_vm.inputCsvName),callback:function ($$v) {_vm.inputCsvName=$$v},expression:"inputCsvName"}}),_c('b',{staticStyle:{"display":"flex","align-items":"end","height":"100%"}},[_vm._v(".csv")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":"Select All","hide-details":""},on:{"click":_vm.toggleSwitch},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_vm._l((Object.keys(
                            _vm.selectedCSVFields
                        )),function(item,idx){return _c('v-col',{key:("CSV-" + idx),attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":item,"hide-details":""},on:{"click":_vm.checkAll},model:{value:(_vm.selectedCSVFields[item]),callback:function ($$v) {_vm.$set(_vm.selectedCSVFields, item, $$v)},expression:"selectedCSVFields[item]"}})],1)})],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.cancelDownload}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.confirmDownload}},[_vm._v(" Download ")])],1)],1)],1),_c('SymbolDetailDialog',{attrs:{"visible":_vm.monitorDialog,"selectedMode":_vm.selectedMode,"symbol":_vm.currentExpanded,"symbolDetailArray":_vm.symbolDetailArray,"table-loading":_vm.monitorDialogLoading,"showHomeLoginDetailSummary":_vm.showHomeLoginDetailSummary,"selectedBook":_vm.selectedBook},on:{"update:visible":function($event){_vm.monitorDialog=$event},"login-click":_vm.loadLoginSummaryHistoryDialog}}),_c('a',{staticStyle:{"display":"none"},attrs:{"id":"downloadAnchorElement"}}),_c('a',{staticStyle:{"display":"none"},attrs:{"id":"homeSymbolBriefDownload"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }